import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Semi Randomly`}</h1>
    <p>{`In `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{`, `}<a parentName="p" {...{
        "href": "/comments",
        "title": "comments"
      }}>{`comments`}</a>{` are sent out `}<a parentName="p" {...{
        "href": "/semi-randomly",
        "title": "semi-randomly"
      }}>{`semi-randomly`}</a>{`, which `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` calls `}<a parentName="p" {...{
        "href": "/comment-routing",
        "title": "comment routing"
      }}>{`comment routing`}</a>{`. This means Random, but with "weights" that make certain outcomes more likely. Comments that are passed on are less likely to be seen. Comments that have higher variance (more divisive) inform group membership, and are thus more likely to be seen. Any comment that is moderated in might, however, be displayed to any participant at any time, to ensure an even spread of votes in the `}<a parentName="p" {...{
        "href": "/polis-opinion-matrix",
        "title": "polis opinion matrix"
      }}>{`polis opinion matrix`}</a>{`.`}</p>
    <p>{`See: `}<a parentName="p" {...{
        "href": "/comment-routing",
        "title": "comment routing"
      }}>{`comment routing`}</a>{``}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      